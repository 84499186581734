<template>
  <div>
    <!--
        {{stepStoredValue}}
-->

    <buildFieldsRows
      :templateContent="templateContent"
      v-model="localStoredValue"
      languageIndex="androidAttributes"
      :isValid="isValid"
      :updatedContent="{
        overallFields: this.result.valueLine,
        //contentID: this.stepType + '_' + this.stepID,
        contentID: this.stepType,
      }"
      @revalidate="$emit('revalidate')"
    ></buildFieldsRows>
  </div>
</template>
<script>
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import rattuxSteps from "@/cfg/rattux.json";

export default {
  components: { buildFieldsRows },
  data() {
    return {
      commandVal: this.stepType.replace("RATTUX_", ""),
    };
  },
  props: ["result", "stepStoredValue", "stepType", "stepID", "isValid"],
  computed: {
    localStoredValue: {
      get() {
        if (typeof this.stepStoredValue.command == "undefined") {
          this.$set(this.stepStoredValue, "command", this.commandVal);
          this.$emit("stored-value-changed", this.stepStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        if (typeof newVal.command == "undefined") {
          this.$set(newVal, "command", this.commandVal);
        }
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let testObject;
      try {
        testObject = JSON.parse(this.result.valueLine.c_obj);
      } catch (error) {
        testObject = {};
      }

      let templateContent = {
        fields: { ...rattuxSteps[this.stepType] },
        additional: this.result.additional,
        sdt: this.result.sdt,
        testObject: testObject,
        disableAllInputs: this.result.disableAllInputs,
      };

      return templateContent;
    },
  },
};
</script>


